<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('options')}}</van-step>
			<van-step>{{translate('deposit_amount')}}</van-step>
			<van-step>{{translate('notification_check')}}</van-step>
		</van-steps>

		<h4 class="h4_title">{{translate('deposit_submission')}}</h4>
		
		<van-form @submit="formSubmit">
			<van-field
				readonly
				clickable
				:label="translate('deposit_method')"
				:value="methodSelect"
				:placeholder="translate('choose_a_deposit_method')"
				@click="showPicker = true"
				:rules="[{ required: true, trigger: 'onChange', message: translate('please_choose_a_deposit_method') }]"
			/>
			
			<van-field
				clearable
				type="number"
				v-model="formItem.total"
				name="total"
				:label="translate('deposit_amount')"
				:placeholder="translate('deposit_amount_support_three')"
				@input="totalFormatter"
				:rules="[{ required: true, trigger: 'onChange', message: translate('please_fill_the_deposit_amount') }]"
			/>
			
			<div class="common_block" v-show="max || min">
				<h4 style="margin: 10px 0">{{translate('deposit_amount')}}</h4>
				<div class="description">
					<p v-show="min" style="color:red">{{translate('minimum_deposit')}}:{{min}} {{ symbol }}</p>
					<p v-show="max" style="color:red">{{translate('maximum_deposit')}}:{{max}} {{ symbol }}</p>
				</div>
			</div>

			<div class="common_block" v-if="tip">
				<h4 style="margin: 10px 0">{{translate('deposit_instructions')}}</h4>
				<div class="description" v-html="tip"></div>
			</div>

			<div class="common_block">
				<van-button type="warning" block native-type="submit" :disabled="myti">{{translate('submit_amount_options')}}</van-button>
			</div>
		</van-form>


		<div class="common_block">
			<p style="text-align: right;color: blue;" @click="goHistory">{{translate('deposit_record')}}</p>
		</div>
		
		<van-popup v-model="showPicker" round position="bottom">
			<van-picker
				:title="translate('deposit_method')"
				show-toolbar
				:columns="methods"
				@cancel="showPicker = false"
				@confirm="onConfirm"
			/>
		</van-popup>
	</div>
</template>

<script>
	export default {
		name: 'rssdeposit_amount',
		data() {
			return {
				myti:false,
				myti1:false,
				myti2:false,
				step_active: 1,

				showPicker: false,

				formItem: {
					c: 0,
					market: 'HK',
					method: "",
					total: "",
					token: ""
				},
				
				methodSelect: "",
				methods: [],

				max: 0,
				min: 0,
				symbol: "",

				deposit_tip: "",
				
				tip: "",
				
				merchants: []
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {

				window.copy = this.copy

				this.formItem.token = this.$route.query.token
				this.formItem.c = this.$route.query.c

				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'rss_deposit/index', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.deposit_tip = res.data.deposit_tip
						this.merchants = res.data.merchants
						
						for (var i in this.merchants) {
							this.methods.push(this.merchants[i]['show_name'])
						}
					}
				})
			},
			totalFormatter(value) {
				if (value && value.indexOf('.') != -1) {

					var num = value.split('.')

					this.formItem.total = num[0] + '.' + num[1].substr(0, 3)
				}
				
			},
			formSubmit() {
				
				var total = parseFloat(this.formItem.total)
				
				if (total <= 0) {
					this.$dialog.alert({
						message: this.translate('please_fill_the_deposit_amount')
					})
					
					return false
				}
				
				if (this.min != 0 && total < this.min) {
					this.$dialog.alert({
						message: this.translate('wrong_amount_minimum_deposit') + this.symbol + ' ' + this.min
					})
					
					return false
				}
				
				if (this.max != 0 && total > this.max) {
					this.$dialog.alert({
						message: this.translate('wrong_amount_maxmum_deposit') + this.symbol + ' ' + this.max
					})
					
					return false
				}

				this.$dialog.confirm({
					message: this.translate('sure_submit_deposit_request')
				}).then(() => {
					this.$toast.loading({
						message: this.translate('please_wait'),
						forbidClick: true,
					})
					this.myti = true;
					let time=setTimeout(()=>{
						this.myti1=true;
						if(this.myti2){
							this.myti1=false;
							this.myti2=false;
							this.myti = false;
						}
						clearTimeout(time)
					},3000);
					this.$axios({
						method: 'post',
						url: 'rss_deposit/insert', 
						data: this.formItem
					}).then ((res) => {
						this.myti2=true;
						if(this.myti1){
							this.myti1=false;
							this.myti2=false;
							this.myti = false;
						}
						this.$toast.clear()
						
						if (res.success) {

							this.$dialog.confirm({
								message: this.translate('apply_success'),
								confirmButtonText: this.translate('to_upload')
							}).then(() => {
								this.$router.push('/rssdeposit/info?token=' + this.formItem.token + '&id=' + res.data.id)
							}).catch(() => {
								this.$router.push('/rssdeposit/success?token=' + this.formItem.token)
							})
						} else {
							this.$dialog.alert({
								message: res.error_text
							})
						}
					})
				}).catch(() => {
					return 0
				})
			},
			onConfirm(value) {
				this.tip = ''
				this.min = 0
				this.max = 0
				this.deposit_exchange_rate = 0
				this.exchange_rate_unit = ""
				
				this.methodSelect = value
				
				for (var i in this.merchants) {
					if (this.merchants[i]['show_name'] == value) {
						this.formItem.method = this.merchants[i]['merchant_id']
						this.min = parseFloat(this.merchants[i]['min'])
						this.max = parseFloat(this.merchants[i]['max'])
						this.tip = this.merchants[i]['tip']
						this.symbol = this.merchants[i]['symbol']
					}
				}

				this.showPicker = false
			},
			goHistory() {
				this.$router.push('/rssdeposit/history?token=' + this.formItem.token)
			},
			copy(str){
                if (window.JsObject != null) {
                    window.JsObject.callCopy(str);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_code": str});
                }
			},
		},
	}
</script>
